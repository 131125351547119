<template>
  <figure v-if="image">
    <RokkaImage
      v-if="config"
      :hash="image?.file?.rokka?.hash ?? ''"
      :config="config"
      :class="imgClass"
      :loading="loading"
      :preload="preload"
      :source-width="image?.file?.rokka?.sourceWidth"
      :source-height="image?.file?.rokka?.sourceHeight"
      :alt="image.alt"
      :title="image.title"
      :file-name="fileName"
      class="bg-gray-100"
    />
    <div v-else class="bg-black text-white aspect-[4/3]">
      <div>Missing image style!!</div>
      <div>{{ styleName }}</div>
    </div>
  </figure>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'
import { RokkaImage } from '#components'
import {
  getLegacyImageStyle,
  type LegacyImageStyle,
} from '~/helpers/imageStyles'

defineOptions({
  name: 'MediaImage',
})

const props = withDefaults(
  defineProps<{
    mid?: number
    caption?: MediaImageFragment['caption']
    copyright?: MediaImageFragment['copyright']
    image?: MediaImageFragment['image']
    imageStyle?: DefineImageStyleConfig | string
    styleName?: LegacyImageStyle
    imgClass?: string
    hideCaption?: boolean
    preload?: boolean
    loading?: 'lazy' | 'eager'
  }>(),
  {
    mid: 0,
    caption: '',
    copyright: '',
    image: undefined,
    imageStyle: undefined,
    styleName: undefined,
    imgClass: '',
    hideCaption: false,
    preload: false,
    loading: 'lazy',
  },
)

const fileName = computed(() => {
  const name =
    props.image?.file?.label || props.image?.title || props.mid || 'image.jpg'
  return name.toString().replace('.png', '.jpg')
})

const config = computed(() => {
  if (props.imageStyle) {
    return props.imageStyle
  } else if (props.styleName) {
    return getLegacyImageStyle(props.styleName)
  }

  return null
})
</script>
