import type { DefineImageStyleConfig } from '#rokka/types'

const column_half_2_3 = defineImageStyle({
  type: 'sizes',
  aspectRatio: 3 / 2,
  sizes: {
    xs: 375,
    sm: 500,
    md: 768,
    lg: 1140,
    xl: 1400,
    '2xl': 1800,
  },
})

const column_one = defineImageStyle({
  type: 'sizes',
  aspectRatio: 2 / 1,
  sizes: {
    xs: 375,
    sm: 500,
    md: 768,
    lg: 1140,
    xl: 1400,
    '2xl': 1800,
  },
})

const column_third = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 375,
    sm: 500,
    md: 768,
    lg: 1140,
    xl: 1400,
    '2xl': 1800,
  },
})

const content_fullwidth = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 400,
      height: 700,
    },
    md: {
      width: 800,
      height: 600,
    },
    lg: {
      width: 1140,
      height: 570,
    },
    xl: {
      width: 2000,
      height: 1000,
    },
  },
})

const teaser_one = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 335,
      height: 250,
    },
    md: {
      width: 675,
      height: 250,
    },
    lg: {
      width: 1140,
      height: 500,
    },
    xl: {
      width: 2000,
      height: 879,
    },
  },
})

const column_half = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 375,
    sm: 500,
    md: 768,
    lg: 1140,
    xl: 1400,
    '2xl': 1800,
  },
})

const column_quarter = defineImageStyle({
  type: 'sizes',
  aspectRatio: 4 / 3,
  sizes: {
    xs: 375,
    sm: 500,
    md: 768,
    lg: 1140,
    xl: 1400,
    '2xl': 1800,
  },
})

const teaser_half = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 335,
      height: 250,
    },
    md: {
      width: 768,
      height: 1152,
    },
    lg: {
      width: 1140,
      height: 760,
    },
  },
})

const teaser_third = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 335,
      height: 500,
    },
    md: {
      width: 335,
      height: 250,
    },
    lg: {
      width: 570,
      height: 760,
    },
  },
})

const product_teaser = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    xs: 300,
    sm: 500,
    md: 750,
    lg: 1000,
  },
})

export const LEGACY_IMAGE_STYLES = {
  content_fullwidth,
  column_half,
  column_half_2_3,
  teaser_one,
  column_quarter,
  product_teaser,
  teaser_half,
  column_one,
  column_third,
  teaser_third,
} as const

export type LegacyImageStyle = keyof typeof LEGACY_IMAGE_STYLES

export function getLegacyImageStyle(
  name: LegacyImageStyle,
): DefineImageStyleConfig {
  return LEGACY_IMAGE_STYLES[name]
}
